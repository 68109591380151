import { createApp } from 'vue'
import App from './App.vue'

import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import actions from './actions.js'
import { getCookie, setCookie } from './util/index.js'

import ElementPlus from 'element-plus'
import zhcn from 'element-plus/es/locale/lang/zh-cn'
import 'element-plus/theme-chalk/index.css'
import 'element-plus/theme-chalk/display.css'

import { Button, Image as VanImage, Cell, NoticeBar, Notify, Popover, Overlay, Loading, Step, Steps, Uploader, Form, Field, CellGroup, Lazyload, Picker, Popup, Tabbar, TabbarItem, Swipe, SwipeItem, PullRefresh, List, Card, Tag, Stepper, Sidebar, SidebarItem, Search, Dialog, Toast, ImagePreview, SubmitBar, Checkbox, CheckboxGroup, SwipeCell, Empty, RadioGroup, Radio, Switch, DropdownMenu, DropdownItem, DatePicker, Cascader } from 'vant'
import 'vant/lib/index.css'

import './assets/fonts/font_4119788_zpb8capewel/iconfont.css';
import { PasswordInput, NumberKeyboard,} from 'vant';



const app = createApp(App)
app.use(PasswordInput);
app.use(NumberKeyboard);
app.use(DatePicker);

const LANGUAGES = {
    en: require('@/assets/locale/lang/en-us.json'),
    zh: require('@/assets/locale/lang/zh-cn.json'),
    id: require('@/assets/locale/lang/id-id.json')
};

app.config.globalProperties.actions = actions;
app.config.globalProperties.apibase = '/api/'
app.config.globalProperties.uploadbase = '/'; // http://distrimall.wbaohe.com/
app.config.globalProperties.langcode = 'zh';
app.config.globalProperties.pagedata = {};
app.config.globalProperties.rootApp = app;

let langcode = getCookie('lh_aog_langcode');
if (!langcode) {
    langcode = app.config.globalProperties.langcode;
    setCookie("lh_aog_langcode", langcode);
} else {
    app.config.globalProperties.langcode = langcode;
}

app.config.globalProperties.LANGUAGES = LANGUAGES;
app.config.globalProperties.LANG = LANGUAGES[langcode];


app.use(router)
app.provide('$axios', axios)

app.use(ElementPlus, {
    locale: zhcn
})

axios.interceptors.request.use(function (config) {
    let agentlogintoken = getCookie('agentlogintoken');
    //let agentloginid = getCookie('agentloginid');
    let lc = getCookie('lh_aog_langcode');

    console.log('lc', lc);
	
	let appId="04e6abb0c8cc448a91061e49ed50a4ec"
    let appSecret="04e6abb0c8cc448a91061e49ed50a4ed"
	
    if (agentlogintoken) {
        config.headers = config.headers ?? {};
        //config.headers.Authorization = config.headers.Authorization??{};
        //config.headers['agentlogintoken'] = agentlogintoken;
	    config.headers['Authorization']=agentlogintoken
        //config.headers['agentloginid'] = agentloginid;
        // config.headers['langcode'] = lc;
		
    }
	config.headers['X-App-Id']=appId
	config.headers['X-App-Secret']=appSecret

    console.log(config);

    return config;
}, function (error) {
    console.info("error: ");
    console.info(error);
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    if (response.data && response.data.retcode == 'CHECKLOGINFAILED') {
        //router.push('/login');
    }
    return response;
}, function (error) {
    console.log(error);
});

app.use(VueAxios, axios);

app.use(Button)
    .use(VanImage)
    .use(Lazyload, {
        lazyComponent: true,
    })
    .use(Form)
    .use(Field)
    .use(CellGroup)
    .use(Picker)
    .use(Popup)
    .use(Tabbar)
    .use(TabbarItem)
    .use(Swipe)
    .use(SwipeItem)
    .use(PullRefresh)
    .use(List)
    .use(Card)
    .use(Tag)
    .use(Stepper)
    .use(Sidebar)
    .use(SidebarItem)
    .use(Search)
    .use(Dialog)
    .use(Toast)
    .use(ImagePreview)
    .use(SubmitBar)
    .use(Checkbox)
    .use(CheckboxGroup)
    .use(SwipeCell)
    .use(Empty)
    .use(RadioGroup)
    .use(Radio)
    .use(Switch)
    .use(Step)
    .use(Steps)
    .use(Uploader)
    .use(NoticeBar)
    .use(Uploader)
    .use(DropdownMenu)
    .use(DropdownItem)
    .use(DatePicker)
    .use(Notify)
    .use(Overlay)
    .use(Loading)
    .use(Popover)
    .use(Cascader)
    .use(Cell)

app.mount('#app')