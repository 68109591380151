export default {
    mobile_prefix: 'https://open-api.memruwen.com.tw/mall/index.php?entry=getmobileprefix',
	
	
	
	
	area_prefix: 'https://open-api.memruwen.com.tw/captcha/sms-country',//登錄選擇地區
	user_login:'https://open-api.memruwen.com.tw/login',  //用戶登錄
	get_banner:'https://open-api.memruwen.com.tw/mall/page/home',//獲取首頁輪播圖
	
	get_category:'https://open-api.memruwen.com.tw/mall/category/list?categoryId=&page=1&pageSize=100',//獲取分類
	goods_list:'https://open-api.memruwen.com.tw/mall/product/list',//商品列表
	spuDetail:'https://open-api.memruwen.com.tw/mall/product/spu-detail',//商品详情
	
	memberInfo:'https://open-api.memruwen.com.tw/mall/member/info', //個人基本信息
	memberMoney:'https://open-api.memruwen.com.tw/mall/goods-money/info',//個人貨款信息
	memberState:'https://open-api.memruwen.com.tw/mall/stat/mine',//個人收益信息
	memberDeposit:"https://open-api.memruwen.com.tw/mall/deposit/info",//個人品牌使用費
	memberDepositLog:"https://open-api.memruwen.com.tw/mall/deposit/log",//個人品牌使用費明細
	
	memberAuthCert:"https://open-api.memruwen.com.tw/mall//auth-cert?refresh=0",//個人證書
	
    // goods_list: 'https://open-api.memruwen.com.tw/mall/index.php?entry=goods',
	memberGetInvite:'https://open-api.memruwen.com.tw/mall/agent/invite-company',//獲取邀请码信息
	memberCodeInvite:'https://open-api.memruwen.com.tw/mall/agent/get-invite-member',
	memberBrandStatus:'https://open-api.memruwen.com.tw/mall/member-brand/status',//獲取經銷商註冊狀態
	memberInviteMe:'https://open-api.memruwen.com.tw/mall/member-brand/invite-me',//获取上级邀请人信息
	memberComeStauts:'https://open-api.memruwen.com.tw/mall/member-brand/go-center',//改变经销商保证金状态
	
	
	goCenter:"https://open-api.memruwen.com.tw/mall/member-brand/go-center",//更新保證金狀態
	memberStatMine:'https://open-api.memruwen.com.tw/mall/stat/mine',//收益信息
	memberBalanceList:'https://open-api.memruwen.com.tw/mall/balance/list',//收益明细
	memberHistoryList:'https://open-api.memruwen.com.tw/mall/goods-money/transferMoneyList',//遷移明細
	memberProfitList:'https://open-api.memruwen.com.tw/mall/profit/list',//待結算收益
	
	memberWithdrawList:'https://open-api.memruwen.com.tw/mall/withdraw/list',//提現明细
	
	addSku:"https://open-api.memruwen.com.tw/mall/cart/add-sku",//加入購物車
	
	
	resetSku:"https://open-api.memruwen.com.tw/mall/cart/reset-sku",//更新購物車
	removeSku:"https://open-api.memruwen.com.tw/mall/cart/remove-sku",//刪除購物車
	
	
	
	cartList:"https://open-api.memruwen.com.tw/mall/cart/list-v2?type=take",//購物車列表
	luckyStatus:"https://open-api.memruwen.com.tw/mall//order/lucky-charm-order-status",//混選套餐次數
	checkOrderCalc:"https://open-api.memruwen.com.tw/mall/order/check-calc-order",//檢查訂單
	orderCalc:"https://open-api.memruwen.com.tw/mall/order/calc-order",//檢查訂單
	calcAvailabeTemplate:"https://open-api.memruwen.com.tw/mall/express-fee/calc-availabe-template",//運費模板
    orderAddorder:"https://open-api.memruwen.com.tw/mall/order/add-order",//創建訂單	
	getPayments:"https://open-api.memruwen.com.tw/mall/trade/create",//获取支付单号
	validatePassword:"https://open-api.memruwen.com.tw/validate-password",//校验密码
	getPay:"https://open-api.memruwen.com.tw/mall/trade/pay",//支付
	
	orderDetail:"https://open-api.memruwen.com.tw/mall/order/detail",//订单详情
	orderDelete:"https://open-api.memruwen.com.tw/mall/order/delete",//删除订单
    orderCancel:"https://open-api.memruwen.com.tw/mall/order/cancel",//删除订单
	
	

    teacherSchedule:"https://open-api.memruwen.com.tw/mall/app_tutor_task/thisRound",//導師進度
	
	addressAll:"https://open-api.memruwen.com.tw/mall/address/all",//獲取所有城市
	addressUserAdd:"https://open-api.memruwen.com.tw/mall/address/user-add",//添加地址
	editressUserAdd:"https://open-api.memruwen.com.tw/mall/address/user-edit",//编辑地址
	addressUserList:"https://open-api.memruwen.com.tw/mall/address/user-list",//地址列表
	addressDel:"https://open-api.memruwen.com.tw/mall/address/user-delete",//刪除地址
	getSmsToken:'https://open-api.memruwen.com.tw/captcha/token',//獲取短信token
	getSms:'https://open-api.memruwen.com.tw/captcha/auth',//獲取短信
	bindCompany:'https://open-api.memruwen.com.tw/mall/agent/bind-company',//註冊經銷商
	
	getInviteLink:"https://open-api.memruwen.com.tw/mall/member-brand/invite-home-agency",//生成邀請鏈接
	getInviteInfo:"https://open-api.memruwen.com.tw/mall/agent/invite",//獲取邀請人信息
	addInfo:"https://open-api.memruwen.com.tw/mall/agent/add-info",//註冊經銷商補全資料
	offlineDesc:"https://open-api.memruwen.com.tw/mall/goods-money/offline-desc",//獲取銀行名字
	addTicket:"https://open-api.memruwen.com.tw/mall/goods-money/add-ticket",//貨款支付
	
	upload:"https://open-api.memruwen.com.tw/upload",//上傳圖片
	
	uploadFile:"https://open-api.memruwen.com.tw/upload-file",//上傳文件
	getQuery:"https://open-api.memruwen.com.tw/mall/exchange-query",//獲取幣種
	
	goodsMoneyList:"https://open-api.memruwen.com.tw/mall/goods-money/list",//貨款明細
	offlineList:"https://open-api.memruwen.com.tw/mall/goods-money/offline-list",//充值明細
	
	
	teamList:"https://open-api.memruwen.com.tw/mall/member-brand/team-list",//团队成員
	
	orderStatusCount:"https://open-api.memruwen.com.tw/mall/order/order-status-count?userType=&type=",//获取订单列表的订单状态
	orderList:"https://open-api.memruwen.com.tw/mall/order/list",//订单列表
	
	
	
	orderTariffStatusCount:"https://open-api.memruwen.com.tw/mall/order/order-status-count?userType=&type=",//获取订单列表的订单状态
	orderTariffList:"https://open-api.memruwen.com.tw/mall/taxOrder/list",//關稅订单列表
	
	orderTariffPay:"https://open-api.memruwen.com.tw/mall/taxOrder/add-ticket",//關稅订单列
	
	memeberTeam:"https://open-api.memruwen.com.tw/mall/member/team",//團隊
	
	addressEdit:"https://open-api.memruwen.com.tw/mall/address/user-edit",//修改地址
	
	
	accountsList:"https://open-api.memruwen.com.tw/mall/accounts",//賬戶列表
	
	commonBanks:"https://open-api.memruwen.com.tw/mall/common/banks",//銀行列表


    checklogin: 'https://open-api.memruwen.com.tw/mall/auth-user',//檢查登錄狀態
	
	
	withdrawApply:'https://open-api.memruwen.com.tw/mall/withdraw/apply',//提现
	
	
    resetPassword:'https://open-api.memruwen.com.tw/reset-password',//找回密码
	
	
	getContractList:'https://open-api.memruwen.com.tw/mall/contract/get-contract-list',//簽約列表
	getCodeList:'https://open-api.memruwen.com.tw/mall/member/code-info',//獲取優惠劵列表
	
	
	addTeacher:'https://open-api.memruwen.com.tw/mall/application_tutor/addApproval',//申请导师
	teacherList:'https://open-api.memruwen.com.tw/mall/application_tutor/list',//申请导师列表
    teacherHistoryList:'https://open-api.memruwen.com.tw/mall/app_tutor_task/list',//导师週期列表
    addSalonCourseApproval:"https://open-api.memruwen.com.tw/mall/app_tutor_task/addSalonCourseApproval",//添加線下沙龍	
	updateSalonCourseApproval:"https://open-api.memruwen.com.tw/mall/app_tutor_task/updateSalonCourseApproval",//修改線下沙龍
	


    goods_cates: 'https://open-api.memruwen.com.tw/mall/index.php?entry=goods&op=category',
    goods_detail: 'https://open-api.memruwen.com.tw/mall/index.php?entry=goods&op=detail&goodsid=',
    goods_props: 'https://open-api.memruwen.com.tw/mall/product/spu-detail?productId=',

    cart: 'https://open-api.memruwen.com.tw/mall/index.php?entry=cart',
    cart_add: 'https://open-api.memruwen.com.tw/mall/index.php?entry=cart&op=add',
    cart_update: 'https://open-api.memruwen.com.tw/mall/index.php?entry=cart&op=update',
    cart_delele: 'https://open-api.memruwen.com.tw/mall/index.php?entry=cart&op=delete',
    
    // member: 'https://open-api.memruwen.com.tw/mall/index.php?entry=member',

    order: 'https://open-api.memruwen.com.tw/mall/index.php?entry=order',
    order_nums: 'https://open-api.memruwen.com.tw/mall/index.php?entry=order&op=nums', // 订单数量
    order_order: 'https://open-api.memruwen.com.tw/mall/index.php?entry=order&op=order', // 直接下单
    order_orderc: 'https://open-api.memruwen.com.tw/mall/index.php?entry=order&op=orderc', // 从购物车下单
    order_placeorder: 'https://open-api.memruwen.com.tw/mall/index.php?entry=order&op=placeorder', // 保存订单
    order_shippingfee: 'https://open-api.memruwen.com.tw/mall/index.php?entry=order&op=shippingfee', // 计算运费
    order_detail: 'https://open-api.memruwen.com.tw/mall/index.php?entry=order&op=detail&orderid=', // 订单详情
    order_pay: 'https://open-api.memruwen.com.tw/mall/index.php?entry=order&op=gopay&orderid=', // 订单支付
    order_receipt: 'https://open-api.memruwen.com.tw/mall/index.php?entry=order&op=receipt&orderid=', // 确认收货
    order_timeout: 'https://open-api.memruwen.com.tw/mall/index.php?entry=order&op=paytimeout&orderid=', // 支付超时
    order_cancel: 'https://open-api.memruwen.com.tw/mall/index.php?entry=order&op=cancel&orderid=', // 取消订单
    order_delete: 'https://open-api.memruwen.com.tw/mall/index.php?entry=order&op=delete&orderid=', // 删除订单

    address: 'https://open-api.memruwen.com.tw/mall/index.php?entry=address',
    address_detail: 'https://open-api.memruwen.com.tw/mall/index.php?entry=address&op=detail&addrid=',
    address_setdefault: 'https://open-api.memruwen.com.tw/mall/index.php?entry=address&op=setdefault&addrid=',
    address_area: 'https://open-api.memruwen.com.tw/mall/index.php?entry=address&op=area&countryid=',
    address_delete: 'https://open-api.memruwen.com.tw/mall/index.php?entry=address&op=delete&addrid=',
    address_save: 'https://open-api.memruwen.com.tw/mall/index.php?entry=address&op=save',
    address_country: 'https://open-api.memruwen.com.tw/mall/index.php?entry=address&op=country',

    credit: 'https://open-api.memruwen.com.tw/mall/index.php?entry=credit',
    recharge: 'https://open-api.memruwen.com.tw/mall/index.php?entry=credit&op=recharge',
    credit_logs: 'https://open-api.memruwen.com.tw/mall/index.php?entry=credit&op=logs',
    credit_recharge_logs: 'https://open-api.memruwen.com.tw/mall/index.php?entry=credit&op=rechargelogs',

    dologin: 'https://open-api.memruwen.com.tw/mall/index.php?entry=agent&op=dologin',
    
    doagentregist: 'https://open-api.memruwen.com.tw/mall/index.php?entry=agent&op=doagentregist',
    getagentinfo: 'https://open-api.memruwen.com.tw/mall/index.php?entry=agent&op=getagentinfo',
    saveidprofile: 'https://open-api.memruwen.com.tw/mall/index.php?entry=agent&op=saveidprofile',
    initcharge: 'https://open-api.memruwen.com.tw/mall/index.php?entry=agent&op=initcharge',
    acontacts: 'https://open-api.memruwen.com.tw/mall/index.php?entry=agent&op=acontacts',
    agrouplist: 'https://open-api.memruwen.com.tw/mall/index.php?entry=agent&op=agrouplist',
    getpfaccount: 'https://open-api.memruwen.com.tw/mall/index.php?entry=agent&op=getpfaccount',
    getaglvinfo: 'https://open-api.memruwen.com.tw/mall/index.php?entry=agent&op=getaglvinfo',

    income: 'https://open-api.memruwen.com.tw/mall/index.php?entry=income',
    income_withdraw: 'https://open-api.memruwen.com.tw/mall/index.php?entry=income&op=withdraw',
    income_withdraw_logs: 'https://open-api.memruwen.com.tw/mall/index.php?entry=income&op=withdrawlogs',
    income_toberecorded: 'https://open-api.memruwen.com.tw/mall/index.php?entry=income&op=toberecorded',
    income_account: 'https://open-api.memruwen.com.tw/mall/index.php?entry=income&op=account',
    income_account_detail: 'https://open-api.memruwen.com.tw/mall/index.php?entry=income&op=account&subop=detail&accountid=',
    income_account_save: 'https://open-api.memruwen.com.tw/mall/index.php?entry=income&op=account&subop=save',
    income_account_delete: 'https://open-api.memruwen.com.tw/mall/index.php?entry=income&op=account&subop=delete&accountid=',
    income_logs: 'https://open-api.memruwen.com.tw/mall/index.php?entry=income&op=logs',

    announce: 'https://open-api.memruwen.com.tw/mall/index.php?entry=showpage&op=announce',
    announcedetail: 'https://open-api.memruwen.com.tw/mall/index.php?entry=showpage&op=announcedetail&id=',

    invite: 'https://open-api.memruwen.com.tw/mall/index.php?entry=invite',
    invite_user: 'https://open-api.memruwen.com.tw/mall/index.php?entry=invite&op=getlist',
	
	
}